import React, { createContext, useCallback, useReducer } from "react";

const initialContext = {
  alert: {
    isShow: false,
    title: null,
    body: null,
    alertType: "info",
    size: "md",
    customBtn1: null,
    customBtn2: null,
  },
};

const UTIL_ACTIONS = {
  TOGGLE_ALERT: "TOGGLE_ALERT",
};

const Context = createContext();
const reducer = (state, { type, ...rest }) => {
  switch (type) {
    case UTIL_ACTIONS.TOGGLE_ALERT: {
      return {
        ...state,
        alert: {
          ...state.alert,
          isShow: !state.alert.isShow,
          title: rest.title || "",
          body: rest.body || "",
          alertType: rest.alertType || "info",
          size: rest.size || "md",
          customBtn1: rest.customBtn1 || null,
          customBtn2: rest.customBtn2 || null,
        },
      };
    }

    default: {
      return state;
    }
  }
};

const UtilContextContainer = (props) => {
  const [state, dispatch] = useReducer(reducer, initialContext);
  const toggleAlert = useCallback(
    (props) => {
      dispatch({ ...props, type: UTIL_ACTIONS.TOGGLE_ALERT });
    },
    [dispatch]
  );

  const value = { ...state, toggleAlert };

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export default Context;

export { UtilContextContainer };
