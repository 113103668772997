import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { UserContextProvider } from "contexts";
import { LoyaltyContextContainer } from "./contexts/LoyaltyContext";
import { UtilContextContainer } from "./contexts/UtilContext";
import { KeycloakContextProvider } from "./contexts/keycloakAuthServiceContext";
import AlertBox from "./components/utils/alert/AlertBox";
import LoadingSpinner from "./components/utils/loadingSpinner/LoadingSpinner";
import Portal from "components/portal/Portal";
import ErrorBoundary from "./ErrorBoundary";
import AuthRoute from "AuthRoute";

import "./vendor/theme/bootstrap-theme.css";
import "react-toastify/dist/ReactToastify.min.css";

const Signup = React.lazy(() =>
  import("./components/account/signup/SignupPage")
);
const LandingPage = React.lazy(() =>
  import("./components/landingpage/LandingPage")
);

const DataProtectionPolicy = React.lazy(() =>
    import("./components/dataprotectionpolicy/DataProtectionPolicy")
);


function App() {
  return (
    <div className="App h-100">
      <ToastContainer
        position="top-center"
        transition={Slide}
        theme="colored"
        pauseOnFocusLoss={false}
      />

      <ErrorBoundary>
        <Suspense fallback={<LoadingSpinner />}>
          <Router>
            <UtilContextContainer>
              <KeycloakContextProvider>
                <UserContextProvider>
                  <LoyaltyContextContainer>
                    <Suspense fallback={<LoadingSpinner />}>
                      <Routes>
                        <Route path="signup/*" element={<Signup />} />
                        <Route
                          path="portal/*"
                          element={
                            <AuthRoute>
                              <Portal />
                            </AuthRoute>
                          }
                        />
                        <Route path="/*" element={<LandingPage />} />
                        <Route path="/data-protection-policy" element={<DataProtectionPolicy />} />
                      </Routes>
                    </Suspense>
                    <AlertBox />
                  </LoyaltyContextContainer>
                </UserContextProvider>
              </KeycloakContextProvider>
            </UtilContextContainer>
          </Router>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default App;
