import React from "react";

import "./LoyaltyCard.css";

const LoyaltyCard = ({ cardNumber = null }) => (
  <div className="w-100 d-flex justify-content-center">
    <div className="w-100 text-primary loyalty-card p-3">
      <div className="card-number-text font-weight-bold">
        {cardNumber ? cardNumber.toString() : "~ unknown"}
      </div>
      <span className="title-text">Loyalty Card</span>
    </div>
  </div>
);

export default LoyaltyCard;
