import React, { useContext, useMemo } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { UserContext } from "contexts";
import Avatar from "./../utils/Avatar/Avatar";
import LoadingSpinner from "../utils/loadingSpinner/LoadingSpinner";
import LoyaltyCard from "components/common/loyaltyCard/LoyaltyCard";

import "./SideProfileView.css";

const SideProfileView = () => {
  const {
    loadingUser,
    logout,
    regionConfig,
    firstName,
    cards,
    points,
    tierData,
    tiers,
  } = useContext(UserContext);

  const cardNumber = useMemo(() => {
    const assignedCard = cards.find((c) => c?.status === "ASSIGNED")?.cardNo;
    return assignedCard ? assignedCard.toString().padStart(16, "0") : null;
  }, [cards]);

  const currentTier = useMemo(() => {
    if (tiers.length > 0) {
      return tiers.find(({ _id }) => _id === tierData?._id);
    }
    return null;
  }, [tierData, tiers]);

  return (
    <Card>
      <Card.Body>
        <img
          className="img-fluid px-lg-3 px-xl-5 pt-lg-2 pt-md-1"
          src="/assets/images/logo.png"
          alt="logo"
        />
        <hr className="border-muted dashed" />
        <div className="text-center my-3">
          <small>
            Welcome to <strong>Vision Care</strong> Loyalty
          </small>
        </div>
        {loadingUser ? (
          <LoadingSpinner />
        ) : (
          <Row className="align-items-center mb-3">
            <Col xs={12} sm={12} lg={12} className="text-center">
              <div className="text-center mt-auto">
                {currentTier ? (
                  <Avatar text={firstName} image={currentTier.imageUrl} />
                ) : null}
                <h6 className="my-4">{firstName}</h6>
              </div>
              <div className="mt-3">
                <LoyaltyCard cardNumber={cardNumber} />
              </div>
            </Col>
            <Col xs={12} sm={12} lg={12} className="my-2">
              <div className="w-100 border text-center rounded p-2 my-2">
                <h5 className="font-weight-bold mb-0">{points}</h5>
                <small className="text-muted text-extra-smaller">
                  {`Redeemable ${
                    regionConfig?.pointConfiguration?.alias || "Points"
                  }`}
                </small>
              </div>
              <div className="w-100 border text-center rounded p-2 my-2">
                <h5 className="font-weight-bold mb-0">
                  {currentTier?.name || "No tier assigned"}
                </h5>
                <small className="text-muted text-extra-smaller">
                  Current Tier
                </small>
              </div>
            </Col>
            <Col xs={12} sm={12} lg={12} className="mt-3 w-100 text-center">
              <Button
                className="w-75 rounded"
                variant="outline-danger"
                onClick={logout}
              >
                Logout
              </Button>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};
export default SideProfileView;
