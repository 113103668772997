import React from "react";

import "./Avatar.css";

const Avatar = ({ text, image }) => {
  return (
    <div className="avatar-container">
      <img className="avatar-badge" src={image} alt="avatar badge" />
      <p className="avatar-text">{text.charAt(0)}</p>
    </div>
  );
};

export default Avatar;
