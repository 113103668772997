import React, {
  createContext,
  useReducer,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { toast } from "react-toastify";
import { UserContext } from "contexts";
import { getAllLocations, getRewards } from "./../services";

const InitialLoyaltyContext = {
  rewards: [],
  isLoadingRewards: false,
  isLoadingConfig: false,
  tierData: {},
  tierConfig: {},
  rewardConfig: {},
  pointConfig: {},
  announcements: [],
  isLoadingAnnouncements: false,
  locations: [],
};

const LOYALTY_ACTIONS = {
  RECEIVED_REWARDS: "RECEIVED_REWARDS",
  LOADING_REWARDS: "LOADING_REWARDS",
  LOADING_LOCATIONS: "LOADING_LOCATIONS",
  RECEIVED_LOCATIONS: "RECEIVED_LOCATIONS",
  LOADING_CONFIG: "LOADING_CONFIG",
  // RECEIVED_CONFIG: "RECEIVED_CONFIG",
  TOGGLE_LOADING_ANNOUNCEMENTS: "TOGGLE_LOADING_ANNOUNCEMENTS",
  RECEIVED_ANNOUNCEMENTS: "RECEIVED_ANNOUNCEMENTS",
};

const Context = createContext();
const reducer = (state, { type, ...rest }) => {
  switch (type) {
    case LOYALTY_ACTIONS.LOADING_REWARDS: {
      return { ...state, isLoadingRewards: rest.status };
    }
    case LOYALTY_ACTIONS.RECEIVED_REWARDS: {
      const sortedRewards = rest.rewards.sort(
        (a, b) => a.pointsStatic - b.pointsStatic
      );
      return { ...state, rewards: sortedRewards, isLoadingRewards: false };
    }
    case LOYALTY_ACTIONS.LOADING_CONFIG: {
      return { ...state, isLoadingConfig: true };
    }
    case LOYALTY_ACTIONS.LOADING_LOCATIONS: {
      return { ...state, status: rest.status };
    }
    case LOYALTY_ACTIONS.RECEIVED_LOCATIONS: {
      return { ...state, locations: rest.locations };
    }
    // TODO: Add again once the new backend has announcements model
    // case LOYALTY_ACTIONS.LOADING_ANNOUNCEMENTS: {
    //   return {
    //     ...state,
    //     isLoadingAnnouncements: !state.isLoadingAnnouncements,
    //   };
    // }
    // case LOYALTY_ACTIONS.RECEIVED_ANNOUNCEMENTS: {
    //   return {
    //     ...state,
    //     announcements: rest.announcements,
    //     isLoadingAnnouncements: false,
    //   };
    // }
    default: {
      return state;
    }
  }
};

const LoyaltyContextContainer = (props) => {
  const { isAuth, isUserProfileLoaded } = useContext(UserContext);
  const [state, dispatch] = useReducer(reducer, InitialLoyaltyContext);

  const loadLocations = useCallback(async () => {
    try {
      dispatch({
        type: LOYALTY_ACTIONS.LOADING_LOCATIONS,
        status: true,
      });

      const locationsResponse = await getAllLocations();
      dispatch({
        type: LOYALTY_ACTIONS.RECEIVED_LOCATIONS,
        locations: locationsResponse,
      });
    } catch (e) {
      console.error(e);
      toast.error(
        <div>
          Failed to load locations!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
          <br />
          <small>If the issue persists, please contact Vision Care.</small>
        </div>
      );
    } finally {
      dispatch({
        type: LOYALTY_ACTIONS.LOADING_LOCATIONS,
        status: false,
      });
    }
  }, [dispatch]);

  const loadRewards = useCallback(async () => {
    try {
      dispatch({
        type: LOYALTY_ACTIONS.LOADING_REWARDS,
        status: true,
      });
      const queryObj = { limit: 5, skip: 0 };
      const rewardsResponse = await getRewards(queryObj);
      dispatch({
        type: LOYALTY_ACTIONS.RECEIVED_REWARDS,
        rewards: rewardsResponse.items,
      });
    } catch (e) {
      console.error(e);
      toast.error(
        <div>
          Failed to load rewards!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
          <br />
          <small>If the issue persists, please contact Vision Care.</small>
        </div>
      );
    } finally {
      dispatch({
        type: LOYALTY_ACTIONS.LOADING_REWARDS,
        status: false,
      });
    }
  }, [dispatch]);

  // TODO: Add again once the new backend has announcements model
  // const loadAnnouncements = useCallback(async () => {
  //   dispatch({ type: LOYALTY_ACTIONS.TOGGLE_LOADING_ANNOUNCEMENTS });
  //   try {
  //     const announcementsResponse = await getAnnouncements();
  //     dispatch({
  //       type: LOYALTY_ACTIONS.RECEIVED_ANNOUNCEMENTS,
  //       announcements: announcementsResponse,
  //     });
  //   } catch (e) {
  //   } finally {
  //     dispatch({ type: LOYALTY_ACTIONS.TOGGLE_LOADING_ANNOUNCEMENTS });
  //   }
  // }, []);

  useEffect(() => {
    if (isAuth && isUserProfileLoaded) {
      loadLocations();
      loadRewards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, isUserProfileLoaded]);

  const value = useMemo(() => ({ ...state }), [state]);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export default Context;

export { LoyaltyContextContainer };
