import { fetchGet, jsonToQueryParam } from "./CommonServiceUtils";
import Constants from "../Constants";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/portal/regions`;

const getPublicRegions = (args) => {
  return fetchGet(`${BASE_URL}/public?${jsonToQueryParam(args)}`);
};

const getAllPublicRegions = async (args) => {
  const limit = 500;
  let totalCount = 0,
    regions = [];
  try {
    do {
      const regionResponse = await getPublicRegions({
        limit,
        skip: regions.length,
        ...args,
      });
      totalCount = regionResponse.total;
      regions = [...regions, ...regionResponse.items];
    } while (regions.length < totalCount);
    return regions;
  } catch (e) {
    Promise.reject(e);
  }
};

export { getPublicRegions, getAllPublicRegions };
