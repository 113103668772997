import React, { useCallback, useContext, useMemo, useState } from "react";
import { Navbar, Nav, Button, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "contexts";
import LoadingSpinner from "components/utils/loadingSpinner/LoadingSpinner";
import LoyaltyCard from "components/common/loyaltyCard/LoyaltyCard";

import "./PortalMainNavbar.css";

const PortalMainNavbar = () => {
  const {
    loadingUser,
    logout,
    regionConfig,
    cards,
    firstName,
    points,
    tierData,
    tiers,
  } = useContext(UserContext);
  const [showAllInfo, setShowAllInfo] = useState(false);
  const location = useLocation();

  const cardNumber = useMemo(() => {
    const assignedCard = cards.find((c) => c?.status === "ASSIGNED")?.cardNo;
    return assignedCard ? assignedCard.toString().padStart(16, "0") : null;
  }, [cards]);

  const currentTier = useMemo(() => {
    if (tiers.length > 0) {
      return tiers.find(({ _id }) => _id === tierData?._id);
    }
    return null;
  }, [tierData, tiers]);

  const onToggelShowAllInfo = useCallback(
    () => setShowAllInfo(!showAllInfo),
    [showAllInfo, setShowAllInfo]
  );

  const onResetShowAllInfo = useCallback(
    () => setShowAllInfo(false),
    [setShowAllInfo]
  );

  return (
    <Navbar
      className="main-nav shadow py-2"
      bg="white"
      expand="lg"
      id="topNavbar"
    >
      <Navbar.Brand href="/portal" className="m-0 p-0 d-md-none">
        <img
          src="/assets/images/logo.png"
          alt="logo"
          height="50"
          width="50"
          className="img-fluid"
        />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={onResetShowAllInfo}
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav fill className="w-100">
          <Nav.Item className="w-100 my-3 d-block d-md-none mini-profile-info rounded p-3">
            {loadingUser ? (
              <LoadingSpinner />
            ) : (
              <>
                {showAllInfo ? (
                  <Row className="align-items-center mb-3">
                    <Col xs={12} sm={6} lg={6} className="text-center">
                      {currentTier ? (
                        <div className="mini-avatar-container">
                          <img
                            className="mini-avatar-badge"
                            src={currentTier.imageUrl}
                            alt="min avatar badge"
                          />
                          <p className="mini-avatar-text">
                            {firstName?.charAt(0)}
                          </p>
                        </div>
                      ) : null}
                      <h6 className="my-2">{firstName}</h6>
                      <div className="mt-3">
                        <LoyaltyCard cardNumber={cardNumber} />
                      </div>
                    </Col>
                    <Col xs={12} sm={6} lg={6} className="my-2">
                      <div className="w-100 d-flex justify-content-center">
                        <div className="w-100 border rounded bg-white p-2 my-2 mini-info-card">
                          <h5 className="font-weight-bold mb-0">{points}</h5>
                          <small className="text-muted text-extra-smaller">
                            {`Redeemable ${
                              regionConfig?.pointConfiguration?.alias ||
                              "Points"
                            }`}
                          </small>
                        </div>
                      </div>
                      <div className="w-100 d-flex justify-content-center">
                        <div className="w-100 border rounded bg-white p-2 my-2 mini-info-card">
                          <h5 className="font-weight-bold mb-0">
                            {currentTier?.name || "No tier assigned"}
                          </h5>
                          <small className="text-muted text-extra-smaller">
                            Current Tier
                          </small>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row className="align-items-center py-2">
                    <Col xs={12} sm={12} lg={6}>
                      <h4>
                        {`Welcome, `}
                        <span className="font-weight-bolder">{firstName}</span>
                      </h4>
                    </Col>
                    <Col xs={12} sm={12} lg={6}>
                      <div className="w-100 d-flex justify-content-center">
                        <div className="w-100 border rounded bg-white p-2 my-2 mini-info-card">
                          <h5 className="font-weight-bold mb-0">{points}</h5>
                          <small className="text-muted text-extra-smaller">
                            {`Redeemable ${
                              regionConfig?.pointConfiguration?.alias ||
                              "Points"
                            }`}
                          </small>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <Col xs={12} sm={12} lg={6} className="text-center">
                  <Button
                    className="rounded"
                    variant="primary"
                    size="sm"
                    disabled={loadingUser}
                    onClick={onToggelShowAllInfo}
                  >
                    Show {showAllInfo ? "Less" : "More"}
                  </Button>
                </Col>
              </>
            )}
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              href="/portal"
              to="/portal"
              active={location.pathname === "/portal"}
              disabled={loadingUser}
            >
              Home
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              href="/portal/rewards"
              to="/portal/rewards"
              active={location.pathname === "/portal/rewards"}
              disabled={loadingUser}
            >
              Rewards
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              href="/portal/transactions"
              to="/portal/transactions"
              active={location.pathname === "/portal/transactions"}
              disabled={loadingUser}
            >
              Transactions
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              href="/portal/announcements"
              to="/portal/announcements"
              active={location.pathname === "/portal/announcements"}
              disabled={loadingUser}
            >
              Announcements
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              href="/portal/about"
              to="/portal/about"
              active={location.pathname === "/portal/about"}
              disabled={loadingUser}
            >
              About
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="w-100 my-3 d-block d-md-none">
            <Button
              className="w-50 rounded"
              variant="outline-danger"
              onClick={logout}
            >
              Logout
            </Button>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default PortalMainNavbar;
