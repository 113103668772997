import { fetchGet, jsonToQueryParam } from "./CommonServiceUtils";
import Constants from "../Constants";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/portal/`;

const getLocations = (queryObj) => {
  return fetchGet(`${BASE_URL}locations?${jsonToQueryParam(queryObj)}`);
};

const getPublicLocations = (args) => {
  return fetchGet(
    `${BASE_URL}locations/public?${jsonToQueryParam(args)}`,
    false
  );
};

const getAllLocations = async (args) => {
  const limit = 500;
  let totalCount = 0,
    locations = [];
  try {
    do {
      const locationsResponse = await getLocations({
        limit,
        skip: locations.length,
        ...args,
      });
      totalCount = locationsResponse.total;
      locations = [...locations, ...locationsResponse.items];
    } while (locations.length < totalCount);
    return locations;
  } catch (e) {
    return Promise.reject(e);
  }
};

export { getPublicLocations, getAllLocations, getLocations };
