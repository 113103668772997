import React, { useReducer, useCallback } from "react";
import Keycloak from "keycloak-js";
import AppContext from "AppContext";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import LoadingComponent from "components/utils/loadingSpinner/LoadingSpinner";
const KeycloakContext = React.createContext();

const KeycloakContextActions = {
  //   SET_DATA: 'setData',
  //   SET_IS_AUTH: 'setIsAuth',
  UPDATE_TOKEN: "setToken",
};
const initialState = {
  token: null,
  //   user: { username: null, email: null },

  //   isAuth: false,
  authComplete: false,
};

const keycloakInstance = Keycloak(AppContext.keycloakConfig);

const reducer = (state, action) => {
  switch (action.type) {
    // case KeycloakContextActions.SET_IS_AUTH: {
    //   return {
    //     ...state,
    //     isAuth: action.status
    //   }
    // }

    case KeycloakContextActions.UPDATE_TOKEN: {
      return {
        ...state,
        token: action.token,
        authComplete: !!action.token,
      };
    }
    default: {
      return state;
    }
  }
};

const constantMock = window.fetch;

const KeycloakContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //   const keycloakLogout = useCallback(
  //     (now = true) => {
  //       const { keycloak, logoutUrl, logout } = state

  //       if (!keycloak) {
  //         return
  //       }

  //       if (now) {
  //         if (keycloak && keycloak.authenticated) {
  //           // Dispatch.success(dispatch, LOGOUT);
  //           logout({ redirectUri: logoutUrl })
  //         }
  //       } else {
  //         setTimeout(() => {
  //           // Dispatch.success(dispatch, LOGOUT);
  //           logout({ redirectUri: logoutUrl })
  //         }, 5000)
  //       }
  //     },
  //     [state]
  //   )

  //   const updateToken = useCallback(
  //     token => {
  //       dispatch({ type: KeycloakContextActions.UPDATE_TOKEN, token })
  //     },
  //     [dispatch]
  //   )
  //   const scheduleTokenRefresh = useCallback(
  //     keycloak => {
  //       if (refreshTokenInterval) {
  //         clearInterval(refreshTokenInterval)
  //       }
  //       refreshTokenInterval = setInterval(() => {
  //         keycloak
  //           .updateToken(TOKEN_REFRESH_RATE)
  //           .then(refreshed => {
  //             if (refreshed) {
  //               updateToken(keycloak.token)
  //             } // else, token is still valid
  //           })
  //           .catch(e => {
  //             // eslint-disable-next-line no-console
  //             console.error(
  //               'Failed to retrieve an updated token or session has expired.',
  //               e
  //             )
  //             keycloakLogout({ now: true })
  //           })
  //       }, TOKEN_REFRESH_RATE * 1000)
  //     },
  //     [keycloakLogout, updateToken]
  //   )

  //   const initialize = useCallback(async () => {
  //     const keycloak = await authenticate()

  //     // console.log(keycloak);
  //     const { token, idTokenParsed } = keycloak || {}
  //     const { preferred_username, email } = idTokenParsed || {}

  //     const login = keycloak.login
  //     const loginUrl = keycloak.createLoginUrl()
  //     const logoutUrl = keycloak.createLogoutUrl()
  //     const logout = keycloak.logout
  //     const registerUrl = keycloak.createRegisterUrl()
  //     const accountUrl = keycloak.createAccountUrl()

  //     dispatch({
  //       type: KeycloakContextActions.SET_DATA,
  //       instance: keycloak,
  //       token,
  //       user: { username: preferred_username, email: email },
  //       login,
  //       loginUrl,
  //       logout,
  //       logoutUrl,
  //       registerUrl,
  //       accountUrl
  //     })
  //     scheduleTokenRefresh(keycloak)
  //   }, [dispatch, scheduleTokenRefresh])

  //   useEffect(() => {
  //     // initialize()

  //     return () => {
  //       if (refreshTokenInterval) {
  //         clearInterval(refreshTokenInterval)
  //       }
  //     }
  //     //eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [])

  //   useEffect(() => {
  //     const { keycloak } = state

  //     if (keycloak && keycloak.authenticated) {
  //       dispatch({ type: KeycloakContextActions.SET_IS_AUTH, status: true })
  //     } else {
  //       dispatch({ type: KeycloakContextActions.SET_IS_AUTH, status: false })
  //     }
  //     //eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [state.keycloak])

  const authEventHandler = useCallback(
    (event, error) => {
      switch (event) {
        // case 'onAuthSuccess': {
        //   dispatch({ type: KeycloakContextActions.SET_IS_AUTH, status: true })
        //   break
        // }
        case "onAuthError":
        case "onAuthLogout": {
          dispatch({ type: KeycloakContextActions.SET_IS_AUTH, status: false });
          break;
        }
        default: {
        }
      }
    },
    [dispatch]
  );

  const updateFetch = useCallback((token) => {
    // localStorage.setItem("token", token);
    // const { so_user_name: username, so_user_email: email, so_user_role: userRole, so_user_id: ownerId, exp, scopes } = decodeToken(token);

    // dispatch({ type: UserContextActions.LOGIN, userRole, username, email, token: token, ownerId, exp, scopes });

    //console.debug("token", token);
    window.fetch = async function () {
      // Get the parameter in arguments
      // Intercept the parameter here

      const body = arguments[1];
      if (body.headers["x-auth"]) {
        delete body.headers["x-auth"];
        // if (isTokenTimeNotExpired(exp)) {
        body.headers["Authorization"] = "Bearer " + token;
        // } else {
        //     logout();
        //     history.push("/login");
        //     return;
        // }
      }

      const response = await constantMock.apply(this, arguments);

      if (response.status === 401 || response.status === 403) {
        //   dispatch({
        //     type: UserContextActions.SET_INIT_SYSTEM_ERROR_MESSAGE,
        //     message:
        //       'You are not authorized to perform certain actions or your session is expired. Logging you out in few seconds...'
        //   })
        //   //token expired
        //   setTimeout(() => {
        //     logout()
        //   }, 3000)
        keycloakInstance.logout();

        // history.push("/login");
      }

      return response;
    };
  }, []);

  const authTokenHandler = useCallback(
    ({ token }) => {
      //   console.debug('token handler:', token)
      if (token) {
        updateFetch(token);
        dispatch({ type: KeycloakContextActions.UPDATE_TOKEN, token });
      } else {
        window.fetch = constantMock;
      }
    },
    [dispatch, updateFetch]
  );
  const value = {
    ...state,
    isAuth: keycloakInstance.authenticated || false,
    login: keycloakInstance.login,
    keycloakLogout: keycloakInstance.logout,
  };
  //   console.debug('Keycloak Context: ', state)

  return (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      LoadingComponent={<LoadingComponent />}
      onEvent={authEventHandler}
      onTokens={authTokenHandler}
    >
      <KeycloakContext.Provider value={value}>
        {props.children}
      </KeycloakContext.Provider>
    </ReactKeycloakProvider>
  );
};

const KeycloakContextConsumer = KeycloakContext.Consumer;

export { KeycloakContext, KeycloakContextProvider, KeycloakContextConsumer };
