import React, { useContext, useEffect } from "react";
import { UserContext } from "contexts";
import LoadingSpinner from "components/utils/loadingSpinner/LoadingSpinner";

const AuthRoute = ({ children }) => {
  const { isAuth, login, initialized } = useContext(UserContext);

  useEffect(() => {
    if (!isAuth && initialized) login();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, initialized]);

  return isAuth && initialized ? children : <LoadingSpinner />;
};

export default AuthRoute;
