import { fetchGet, jsonToQueryParam, fetchPost } from "./CommonServiceUtils";
import Constants from "../Constants";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/portal/`;

const getRewards = (queryObj) => {
  return fetchGet(`${BASE_URL}rewards?${jsonToQueryParam(queryObj)}`);
};

const getRewardLogs = (queryObj) => {
  return fetchGet(`${BASE_URL}redemptionlogs?${jsonToQueryParam(queryObj)}`);
};

const redeemReward = (payload) => {
  return fetchPost(`${BASE_URL}rewardRedeem`, payload);
};

export { getRewards, redeemReward, getRewardLogs };
