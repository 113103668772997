import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakContext } from "contexts";
import {
  getRegionalPointConfiguration,
  getTiers,
  getAccountDetails,
  getAllPublicRegions,
  getOrganizationDetails,
  getPointRules,
} from "services";

const UserContext = React.createContext();

const UserContextActions = {
  SET_ORGANIZATION: "setOrganization",
  SET_REGIONS: "setRegions",
  SET_POINTS_CONFIG: "setPointConfig",
  SET_PARTNER_REWARDS_LIST: "setPartnerRewardsList",
  IS_LOADING_PARTNER_REWARDS: "isLoadingPartnerRewards",
  SET_TIERS: "setTiers",
  IS_LOADING_USER: "isLoadingUser",
  IS_LOADING_TIERS: "isLoadingTiers",
  SET_PROFILE_DATA: "setProfileData",
  IS_EDITING_USER: "isEditingUser",
  RELOAD_POINTS: "reloadPoints",
  IS_LOADING_POINT_RULES: "isLoadingPointRules",
  SET_POINT_RULES: "setPointRules",
};

const initialRegionState = {
  _id: "",
  organizationId: "",
  regionName: "Barbados",
  defaultCountryISO2Code: "BB",
  defaultCurrencyCode: "BBD",
  regionIconUrl: "",
};

const initialState = {
  isRunningSystemInit: true,
  regionId: "",
  PartnerRewardsList: "",
  loadingPartnerRewards: false,
  organization: {
    organizationName: "",
    organizationLogoImageUrl: "",
    address: {
      street: "",
      city: "",
      zip: "",
    },
    regions: [],
    configuration: {
      cardConfiguration: {
        loyaltyCardNumberLength: 16,
      },
    },
  },
  regionConfig: {},
  isLoadingTiers: false,
  tiers: [],
  tierData: null,
  loadingUser: false,
  cards: [],
  isUserProfileLoaded: false,
  editingUser: false,
  reloadingPoints: false,
  isLoadingPointRules: false,
  pointRules: [],
};

//
const reducer = (state, action) => {
  switch (action.type) {
    case UserContextActions.SET_ORGANIZATION: {
      return {
        ...state,
        organization: {
          ...action.organization,
          configuration: {
            ...state.organization.configuration,
            ...action.organization.configuration,
          },
        },
        isRunningSystemInit: false,
        systemInitFailMessage: null,
      };
    }

    case UserContextActions.IS_LOADING_USER: {
      return { ...state, loadingUser: action.status };
    }

    case UserContextActions.IS_LOADING_PARTNER_REWARDS: {
      return { ...state, loadingPartnerRewards: action.status };
    }

    case UserContextActions.SET_PROFILE_DATA: {
      return { ...state, ...action.data, isUserProfileLoaded: true };
    }

    case UserContextActions.IS_EDITING_USER: {
      return { ...state, editingUser: action.status };
    }

    case UserContextActions.SET_POINTS_CONFIG: {
      return { ...state, regionConfig: action.configData };
    }

    case UserContextActions.IS_LOADING_TIERS: {
      return { ...state, isLoadingTiers: action.status };
    }

    case UserContextActions.SET_TIERS: {
      return { ...state, tiers: action.tiersData };
    }

    case UserContextActions.RELOAD_POINTS: {
      return { ...state, reloadingPoints: action.status };
    }
    case UserContextActions.SET_PARTNER_REWARDS_LIST: {
      return { ...state, PartnerRewardsList: action.status };
    }

    case UserContextActions.SET_REGIONS: {
      return {
        ...state,
        organization: { ...state.organization, regions: action.regions },
        regionId: action.selectedRegion
          ? action.selectedRegion._id
          : state.regionId,
      };
    }

    case UserContextActions.IS_LOADING_POINT_RULES: {
      return { ...state, isLoadingPointRules: action.status };
    }

    case UserContextActions.SET_POINT_RULES: {
      return { ...state, pointRules: action.pointRules };
    }

    default:
      return state;
  }
};

const UserContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    keycloakLogout: logout,
    login: keycloakLogin,
    authComplete,
  } = useContext(KeycloakContext);

  const { keycloak, initialized } = useKeycloak();
  const isAuth = keycloak.authenticated || false;

  const loadProfile = useCallback(async () => {
    try {
      dispatch({
        type: UserContextActions.IS_LOADING_USER,
        status: true,
      });
      const profileResponse = await getAccountDetails();
      dispatch({
        type: UserContextActions.SET_PROFILE_DATA,
        data: profileResponse,
      });
    } catch (e) {
      console.error(e);
      toast.error(
        <div>
          Failed to load user profile!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
          <br />
          <small>If the issue persists, please contact Vision Care.</small>
        </div>
      );
    } finally {
      dispatch({
        type: UserContextActions.IS_LOADING_USER,
        status: false,
      });
    }
  }, [dispatch]);

  const loadOrganization = useCallback(async () => {
    try {
      const organizationResponse = await getOrganizationDetails();
      dispatch({
        type: UserContextActions.SET_ORGANIZATION,
        organization: organizationResponse,
      });
    } catch (e) {
      console.error(e);
      toast.error(
        <div>
          Failed to organization details!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
          <br />
          <small>If the issue persists, please contact Vision Care.</small>
        </div>
      );
    }
  }, [dispatch]);

  const loadPointsConfig = useCallback(async () => {
    try {
      const pointsConfigResponse = await getRegionalPointConfiguration();
      dispatch({
        type: UserContextActions.SET_POINTS_CONFIG,
        configData: pointsConfigResponse,
      });
    } catch (e) {
      toast.error(
        <div>
          Failed to load points configurations!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
          <br />
          <small>If the issue persists, please contact Vision Care.</small>
        </div>
      );
    }
  }, [dispatch]);

  const loadTiers = useCallback(async () => {
    try {
      dispatch({
        type: UserContextActions.IS_LOADING_TIERS,
        status: true,
      });
      const tiersResponse = await getTiers({});
      dispatch({
        type: UserContextActions.SET_TIERS,
        tiersData: tiersResponse.items,
      });
    } catch (e) {
      toast.error(
        <div>
          Failed to load tiers!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
          <br />
          <small>If the issue persists, please contact Vision Care.</small>
        </div>
      );
    } finally {
      dispatch({
        type: UserContextActions.IS_LOADING_TIERS,
        status: false,
      });
    }
  }, [dispatch]);

  const loadPointRules = useCallback(async () => {
    try {
      dispatch({
        type: UserContextActions.IS_LOADING_POINT_RULES,
        status: true,
      });
      const tiersResponse = await getPointRules({});
      dispatch({
        type: UserContextActions.SET_POINT_RULES,
        pointRules: tiersResponse.items,
      });
    } catch (e) {
      toast.error(
        <div>
          Failed to load point rules!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
          <br />
          <small>If the issue persists, please contact Vision Care.</small>
        </div>
      );
    } finally {
      dispatch({
        type: UserContextActions.IS_LOADING_POINT_RULES,
        status: false,
      });
    }
  }, [dispatch]);

  const refreshUserPoints = useCallback(async () => {
    try {
      dispatch({
        type: UserContextActions.RELOAD_POINTS,
        status: true,
      });
      const profileResponse = await getAccountDetails();
      dispatch({
        type: UserContextActions.SET_PROFILE_DATA,
        data: profileResponse,
      });
      dispatch({
        type: UserContextActions.RELOAD_POINTS,
        status: false,
      });
    } catch (e) {
      dispatch({
        type: UserContextActions.RELOAD_POINTS,
        status: false,
      });
      console.error(e);
      toast.error(
        <div>
          Failed to refresh user profile!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
          <br />
          <small>If the issue persists, please contact Vision Care.</small>
        </div>
      );
    }
  }, []);

  const loadPublicRegions = useCallback(async () => {
    try {
      const regionResponse = await getAllPublicRegions();
      const userRegion = regionResponse.find((item) => item.isDefaultRegion);

      dispatch({
        type: UserContextActions.SET_REGIONS,
        regions: regionResponse,
        selectedRegion: userRegion,
      });
    } catch (e) {
      console.error(e);
      toast.error(
        <div>
          Failed to load regions!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
          <br />
          <small>If the issue persists, please contact Vision Care.</small>
        </div>
      );
    }
  }, [dispatch]);

  const setIsEditingUser = useCallback(
    (status) => dispatch({ type: UserContextActions.IS_EDITING_USER, status }),
    [dispatch]
  );

  const setEditedProfile = useCallback(
    (response) =>
      dispatch({ type: UserContextActions.SET_PROFILE_DATA, data: response }),
    [dispatch]
  );

  useEffect(() => {
    (async () => {
      if (authComplete) {
        loadProfile();
        loadOrganization();
        loadPointsConfig();
        loadTiers();
        loadPointRules();
      }
    })();
    // eslint-disable-next-line
  }, [authComplete]);

  useEffect(() => {
    loadPublicRegions();
    // eslint-disable-next-line
  }, []);

  const selectedRegion = useMemo(() => {
    if (state.regionId && state.organization?.regions) {
      return (
        state.organization?.regions.find(
          (item) => item._id === state.regionId
        ) || initialRegionState
      );
    }
    return initialRegionState;
  }, [state.regionId, state.organization]);

  const value = useMemo(
    () => ({
      ...state,
      selectedRegion,
      isAuth,
      initialized,
      username: state.firstName,
      login: keycloakLogin,
      logout,
      setIsEditingUser,
      setEditedProfile,
      refreshUserPoints,
      loadProfile,
    }),
    [
      state,
      initialized,
      isAuth,
      keycloakLogin,
      logout,
      selectedRegion,
      loadProfile,
      refreshUserPoints,
      setEditedProfile,
      setIsEditingUser,
    ]
  );

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

const UserContextConsumer = UserContext.Consumer;

export {
  UserContext,
  UserContextProvider,
  UserContextConsumer,
  UserContextActions,
};
