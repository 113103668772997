import React, { Suspense } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import LoadingSpinner from "./../utils/loadingSpinner/LoadingSpinner";
import SideProfileView from "./../profile/SideProfileView";
import PortalMainNavbar from "./../navbars/PortalMainNavbar";

const Home = React.lazy(() => import("./../home/Home"));
const Profile = React.lazy(() => import("./../profile/Profile"));
const RewardsTab = React.lazy(() => import("./../rewards/RewardsTab"));
const Transactions = React.lazy(() => import("./../transactions/Transactions"));
const About = React.lazy(() => import("./../about/About"));
const Announcements = React.lazy(() =>
  import("./../announcements/Announcements")
);

const Portal = () => {
  return (
    <div id="portal-view" className="container h-100">
      <Row className="h-100">
        <Col className="my-3">
          <Row>
            <Col md={5} lg={4} xl={3} className="d-none d-md-block">
              <SideProfileView />
            </Col>
            <Col md={7} lg={8} xl={9}>
              <PortalMainNavbar />
              <Card className="mt-4">
                <Card.Body>
                  <Suspense fallback={<LoadingSpinner />}>
                    <Routes>
                      <Route>
                        <Route index element={<Home />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="rewards" element={<RewardsTab />} />
                        <Route path="transactions" element={<Transactions />} />
                        <Route
                          path="announcements"
                          element={<Announcements />}
                        />
                        <Route path="about" element={<About />} />
                      </Route>
                    </Routes>
                  </Suspense>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Portal;
