import React from 'react';
import {Spinner} from 'react-bootstrap';

const LoadingSpinner = (props) => {
    return (<div className="w-100 h-100 my-2">
        <div className="d-flex justify-content-center align-items-center h-100">
            <Spinner animation="border" variant="primary" {...props} />
        </div>
    </div>)
}
export default LoadingSpinner;